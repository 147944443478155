import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import profilePic from 'assets/profile.jpg';

const description = [
  'Je suis un jeune homme passionné par le monde de l\'automobile et j\'ai très vite compris que je voulais travailler dans cette industrie.',
  'Ma passion et motivation se traduisent par une volonté d\'apprendre et de développer mes connaissances dans mon métier.',
  'Jeune en expérience et polyvalent, j\'ai envie de découvrir différentes techniques, nouvelles technologies et expériences avec d\'autres profesionnels en carrosserie.',
  'Je suis toujours à la recherche de l\'excellence dans la finition et dans la qualité de mon travail, avec une écoute active pour comprendre et apprendre.',
  'Agile avec les nouvelles technologies et les réseaux sociaux, j\'ai aussi de l\'expérience dans le community management lié aux différentes plateformes.',
];

export default function Me() {
  return (
    <Grid container spacing={2} mt={10} mb={10}>
      <Grid item xs={0} md={2} />
      <Grid
        item
        xs={12}
        md={2}
        sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <img src={profilePic} alt="profile" style={{ maxWidth: 200, width: '100%', boxShadow: '0px 0px 15px 0px #000000' }} />
        <Typography sx={{ paddingTop: 0.5 }}>Né en 1999</Typography>
        <Typography sx={{ paddingTop: 0.5 }}>Permis B</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', m: 1,
        }}
      >
        {description.map((line) => (
          <Typography key={line} sx={{ paddingTop: 0.5, textAlign: 'center' }}>{line}</Typography>
        ))}
      </Grid>
      <Grid item xs={0} md={2} />
    </Grid>
  );
}
