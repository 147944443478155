import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Box, styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player';

import { VideoType } from '../../assets/pictures';

const ImageGalleryList = styled('ul')(({ theme }) => ({
  display: 'grid',
  padding: 0,
  margin: theme.spacing(0, 4),
  gap: 8,
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));

interface PortfolioProps {
  title: string,
  videos: VideoType[],
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '90%',
  bgcolor: '#000000',
  boxShadow: 24,
  p: 0,
};

export default function Portfolio({ title, videos }: PortfolioProps) {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<VideoType | null>();

  const onSelect = (item: VideoType) => {
    setSelectedItem(item);
    setVideoModalOpen(true);
  };

  const onCloseModal = () => {
    setVideoModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <Grid container spacing={2} mt={10} mb={10}>
      <Grid item xs={12} mb={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>{title}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ImageGalleryList>
          {videos
            .filter((item) => !!item.video)
            .map((item) => (
              <ImageListItem key={item.title} onClick={() => onSelect(item)} sx={{ cursor: 'pointer', maxHeight: 300, overflow: 'hidden' }}>
                <img
                  src={`${item.show}`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                />
              </ImageListItem>
            ))}
        </ImageGalleryList>
      </Grid>

      {videoModalOpen && selectedItem && (
        <Modal
          open={videoModalOpen}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ReactPlayer
              url={selectedItem.video}
              playing
              controls
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    disablePictureInPicture: true,
                    controlsList: 'nodownload noremoteplayback',
                  },
                },
              }}
            />
          </Box>
        </Modal>
      )}
    </Grid>
  );
}
