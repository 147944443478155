import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const studies = [
  {
    school: 'Association ouvrière des Compagnons du Devoir Et du Tour de France',
    year: '2019',
    title: 'Baccalauréat Professionnel en Réparation des Carrosseries',
  },
  {
    school: 'Association ouvrière des Compagnons du Devoir Et du Tour de France',
    year: '2018',
    title: 'C.A.P. en Réparation des Carrosseries',
  },
  {
    school: 'Ensemble Scolaire Notre-Dame Les Oiseaux',
    year: '2017',
    title: 'Baccalauréat Technique - Sciences et Techniques du Management de la Gestion',
  },
];

export default function Studies() {
  return (
    <Grid container spacing={2} mt={10} mb={10}>
      <Grid item xs={12} mb={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h3">Diplômes</Typography>
      </Grid>
      {studies.map((study) => (
        <Grid key={study.title} item mt={5} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 1,
              }}
            >
              <Typography variant="h6" sx={{ textAlign: 'center' }}>{study.title}</Typography>
              <Typography sx={{ paddingTop: 1, textAlign: 'center' }}>{study.school}</Typography>
              <Typography variant="overline" sx={{ textAlign: 'center' }}>{study.year}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
