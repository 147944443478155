import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import solaire from 'assets/solaire.jpg';
import schumacher from 'assets/schumacher.jpg';

const jobs = [
  {
    company: 'Carrosserie Solaire',
    job: 'Carrossier (Échelon 6)',
    logo: solaire,
    year: '2020 - ...',
    descriptions: [
      'Ouvrier qualifié rattaché au service des réparations des carrosseries',
      'Réparation méthodologique, démontage/remontage tôlerie',
      'Gestion des pièces et du suivi pour les voitures clients',
      'Notions en préparation carrosseries, peinture, détails et finitions',
    ],
  },
  {
    company: 'Groupe Schumacher',
    job: 'Carrossier (Échelon 3)',
    logo: schumacher,
    year: '2017 - 2020',
    descriptions: [
      'Ouvrier qualifié rattaché au service après-vente',
      'Réparation méthodologique, soudure, tôlerie et chassimétrie',
      'Formation professionnelle pendant 2 ans d\'apprentissage',
      'Notions en préparation carrosseries, peinture, expertises et devis',
    ],
  },
];

export default function Jobs() {
  return (
    <Grid container spacing={2} mt={10} mb={10}>
      <Grid item xs={12} mb={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h3">Expériences</Typography>
      </Grid>
      {jobs.map((job) => (
        <Grid key={job.company} item mt={5} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={0} md={2} />
            <Grid
              item
              xs={12}
              md={!job.descriptions || job.descriptions.length === 0 ? 12 : 3}
              sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              {!!job.logo && <img src={job.logo} alt="" style={{ maxWidth: '100px', maxHeight: '70px' }} />}
              <Typography variant="h6" sx={{ paddingTop: 1 }}>{job.company}</Typography>
              <Typography variant="overline">{job.year}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 1,
              }}
            >
              {!!job.job && (
                <Typography
                  component="div"
                  sx={{
                    textAlign: 'center', textDecoration: 'underline', paddingBottom: 1,
                  }}
                >
                  {job.job}
                </Typography>
              )}
              {job.descriptions && job.descriptions.length > 0 && (
                <>
                  {job.descriptions.map((description) => (
                    <Typography key={description} component="div" sx={{ textAlign: 'center' }}>{description}</Typography>
                  ))}
                </>
              )}
            </Grid>
            <Grid item xs={0} md={2} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
