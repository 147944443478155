import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { PictureType } from '../../assets/pictures';

interface PicModalProps {
  open: boolean,
  close: Function,
  item?: PictureType | null,
}

export default function PicModal({ open, close, item }: PicModalProps) {
  const [picIndex, setPicIndex] = useState(0);

  const closeModal = () => {
    setPicIndex(0);
    close();
  };

  return (
    <>
      {open && item && (
        <Lightbox
          mainSrc={item.pics[picIndex]}
          nextSrc={item.pics.length > 1 ? item.pics[(picIndex + 1) % item.pics.length] : undefined}
          prevSrc={item.pics.length > 1 ? item.pics[(picIndex + item.pics.length - 1) % item.pics.length] : undefined}
          onCloseRequest={() => closeModal()}
          onMovePrevRequest={() => setPicIndex((picIndex + item.pics.length - 1) % item.pics.length)}
          onMoveNextRequest={() => setPicIndex((picIndex + 1) % item.pics.length)}
          imageTitle={`${item.title} - ${picIndex + 1}/${item.pics.length}`}
          imagePadding={50}
        />
      )}
    </>
  );
}

PicModal.defaultProps = {
  item: null,
};
