import React from 'react';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';

export default function Copyright() {
  return (
    <Grid container spacing={2} mt={3} mb={3} sx={{ justifyContent: 'center' }}>
      <Grid item sx={{ width: '80%', borderBottom: '1px solid #aaaaaa' }}>
        <Divider />
      </Grid>
    </Grid>
  );
}
