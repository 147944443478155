// Apprentissage

import sandero1 from 'assets/photos/sandero/r_IMG_0066.jpg';
import sandero2 from 'assets/photos/sandero/r_IMG_0067.jpg';
import sandero3 from 'assets/photos/sandero/r_IMG_0068.jpg';
import sandero4 from 'assets/photos/sandero/r_IMG_0075.jpg';
import sandero5 from 'assets/photos/sandero/r_IMG_0078.jpg';
import sandero6 from 'assets/photos/sandero/r_IMG_0214.jpg';
import sandero7 from 'assets/photos/sandero/r_IMG_0215.jpg';
import sandero8 from 'assets/photos/sandero/r_IMG_0216.jpg';
import sandero9 from 'assets/photos/sandero/r_IMG_0221.jpg';
import sandero10 from 'assets/photos/sandero/r_IMG_0222.jpg';
import sandero11 from 'assets/photos/sandero/r_IMG_0223.jpg';
import sandero12 from 'assets/photos/sandero/r_IMG_0232.jpg';
import sandero13 from 'assets/photos/sandero/r_IMG_0233.jpg';
import sandero14 from 'assets/photos/sandero/r_IMG_0234.jpg';

import peugeot20081 from 'assets/photos/2008-redressage/IMG_5814_r.jpg';
import peugeot20082 from 'assets/photos/2008-redressage/IMG_5815_r.jpg';
import peugeot20083 from 'assets/photos/2008-redressage/IMG_5836_r.jpg';
import peugeot20084 from 'assets/photos/2008-redressage/IMG_5838_r.jpg';
import peugeot20085 from 'assets/photos/2008-redressage/IMG_5871_r.jpg';
import peugeot20086 from 'assets/photos/2008-redressage/IMG_5872_r.jpg';
import peugeot20087 from 'assets/photos/2008-redressage/IMG_5875_r.jpg';

import basedecaisse1 from 'assets/photos/bas-de-caisse/IMG_0440_r.jpg';
import basedecaisse2 from 'assets/photos/bas-de-caisse/IMG_0442_r.jpg';
import basedecaisse3 from 'assets/photos/bas-de-caisse/IMG_0488_r.jpg';

// Réalisations

import audi1 from 'assets/photos/rea/Audi/r_IMG_0369.jpg';
import audi2 from 'assets/photos/rea/Audi/r_IMG_0378.jpg';

import bmw1 from 'assets/photos/rea/BMW/r_IMG_6952.jpg';
import bmw2 from 'assets/photos/rea/BMW/r_IMG_6955.jpg';
import bmw3 from 'assets/photos/rea/BMW/r_IMG_6957.jpg';
import bmw4 from 'assets/photos/rea/BMW/r_IMG_6960.jpg';
import bmw5 from 'assets/photos/rea/BMW/r_IMG_6963.jpg';
import bmw6 from 'assets/photos/rea/BMW/r_IMG_6964.jpg';
import bmw7 from 'assets/photos/rea/BMW/r_IMG_6965.jpg';
import bmw8 from 'assets/photos/rea/BMW/r_IMG_6967.jpg';

import fiat1 from 'assets/photos/rea/Fiat/r_IMG_6553.jpg';

import ford1 from 'assets/photos/rea/Ford/r_IMG_0262.jpg';
import ford2 from 'assets/photos/rea/Ford/r_IMG_0263.jpg';
import ford3 from 'assets/photos/rea/Ford/r_IMG_0264.jpg';
import ford4 from 'assets/photos/rea/Ford/r_IMG_0271.jpg';
import ford5 from 'assets/photos/rea/Ford/r_IMG_0272.jpg';
import ford6 from 'assets/photos/rea/Ford/r_IMG_0274.jpg';
import ford7 from 'assets/photos/rea/Ford/r_IMG_0367.jpg';
import ford8 from 'assets/photos/rea/Ford/r_IMG_0371.jpg';

import stellantis1 from 'assets/photos/rea/PSA/r_IMG_6101.jpg';
import stellantis2 from 'assets/photos/rea/PSA/r_IMG_6350.jpg';
import stellantis3 from 'assets/photos/rea/PSA/r_IMG_6393.jpg';
import stellantis4 from 'assets/photos/rea/PSA/r_IMG_6400.jpg';
import stellantis5 from 'assets/photos/rea/PSA/r_IMG_6401.jpg';
import stellantis6 from 'assets/photos/rea/PSA/r_IMG_6556.jpg';
import stellantis7 from 'assets/photos/rea/PSA/r_IMG_6557.jpg';
import stellantis8 from 'assets/photos/rea/PSA/r_IMG_6560.jpg';
import stellantis9 from 'assets/photos/rea/PSA/r_IMG_6562.jpg';
import stellantis10 from 'assets/photos/rea/PSA/r_IMG_6564.jpg';
import stellantis11 from 'assets/photos/rea/PSA/r_IMG_6565.jpg';
import stellantis12 from 'assets/photos/rea/PSA/r_IMG_6569.jpg';
import stellantis13 from 'assets/photos/rea/PSA/r_IMG_6570.jpg';
import stellantis14 from 'assets/photos/rea/PSA/r_IMG_6638.jpg';
import stellantis15 from 'assets/photos/rea/PSA/r_IMG_6656.jpg';

import renault1 from 'assets/photos/rea/Renault/r_IMG_7103.jpg';
import renault2 from 'assets/photos/rea/Renault/r_IMG_7106.jpg';
import renault3 from 'assets/photos/rea/Renault/r_IMG_7109.jpg';
import renault4 from 'assets/photos/rea/Renault/r_IMG_7113.jpg';

import vw1 from 'assets/photos/rea/VW/r_IMG_0588.jpg';
import vw2 from 'assets/photos/rea/VW/r_IMG_0589.jpg';
import vw3 from 'assets/photos/rea/VW/r_IMG_0596.jpg';
import vw4 from 'assets/photos/rea/VW/r_IMG_6334.jpg';
import vw5 from 'assets/photos/rea/VW/r_IMG_6346.jpg';
import vw6 from 'assets/photos/rea/VW/r_IMG_6982.jpg';
import vw7 from 'assets/photos/rea/VW/r_IMG_6983.jpg';
import vw8 from 'assets/photos/rea/VW/r_IMG_6985.jpg';
import vw9 from 'assets/photos/rea/VW/r_IMG_6993.jpg';

export interface PictureType {
  title: string;
  show: string;
  pics: string[];
}

export interface VideoType {
  title: string;
  show: string;
  video: string;
}

export const picturesRea = [
  {
    title: 'Audi',
    show: audi2,
    pics: [
      audi1,
      audi2,
    ],
  },
  {
    title: 'BMW',
    show: bmw4,
    pics: [
      bmw1,
      bmw2,
      bmw3,
      bmw4,
      bmw5,
      bmw6,
      bmw7,
      bmw8,
    ],
  },
  {
    title: 'Fiat',
    show: fiat1,
    pics: [
      fiat1,
    ],
  },
  {
    title: 'Ford',
    show: ford4,
    pics: [
      ford1,
      ford2,
      ford3,
      ford4,
      ford5,
      ford6,
      ford7,
      ford8,
    ],
  },
  {
    title: 'Peugeot - Citroën',
    show: stellantis10,
    pics: [
      stellantis1,
      stellantis2,
      stellantis3,
      stellantis4,
      stellantis5,
      stellantis6,
      stellantis7,
      stellantis8,
      stellantis9,
      stellantis10,
      stellantis11,
      stellantis12,
      stellantis13,
      stellantis14,
      stellantis15,
    ],
  },
  {
    title: 'Renault',
    show: renault1,
    pics: [
      renault1,
      renault2,
      renault3,
      renault4,
    ],
  },
  {
    title: 'Volkswagen',
    show: vw4,
    pics: [
      vw1,
      vw2,
      vw3,
      vw4,
      vw5,
      vw6,
      vw7,
      vw8,
      vw9,
    ],
  },
];

export const videoRea = [
  {
    title: 'Peugeot',
    show: stellantis1,
    video: '/videos/307.mp4',
  },
  {
    title: 'BMW',
    show: bmw4,
    video: '/videos/bmw.mp4',
  },
  {
    title: 'Fiat',
    show: fiat1,
    video: '/videos/fiat.mp4',
  },
  {
    title: 'Volkswagen',
    show: vw2,
    video: '/videos/vw.mp4',
  },
];

export const picturesApprentissage = [
  {
    title: 'Dacia Sandero - Marbre',
    show: sandero1,
    pics: [
      sandero1,
      sandero2,
      sandero3,
      sandero4,
      sandero5,
      sandero6,
      sandero7,
      sandero8,
      sandero9,
      sandero10,
      sandero11,
      sandero12,
      sandero13,
      sandero14,
    ],
  },
  {
    title: 'Peugeot 2008 - Redressage',
    show: peugeot20083,
    pics: [
      peugeot20081,
      peugeot20082,
      peugeot20083,
      peugeot20084,
      peugeot20085,
      peugeot20086,
      peugeot20087,
    ],
  },
  {
    title: 'Bas de caisse',
    show: basedecaisse2,
    pics: [
      basedecaisse1,
      basedecaisse2,
      basedecaisse3,
    ],
  },
];
