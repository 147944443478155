import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { styled } from '@mui/material';
import PicModal from 'components/PicModal';

import { PictureType } from '../../assets/pictures';

const ImageGalleryList = styled('ul')(({ theme }) => ({
  display: 'grid',
  padding: 0,
  margin: theme.spacing(0, 4),
  gap: 8,
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));

interface PortfolioProps {
  title: string,
  pictures: PictureType[],
}

export default function Portfolio({ title, pictures }: PortfolioProps) {
  const [picModalOpen, setPicModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PictureType | null>();

  const onSelect = (item: PictureType) => {
    setSelectedItem(item);
    setPicModalOpen(true);
  };

  const onCloseModal = () => {
    setPicModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <Grid container spacing={2} mt={10} mb={10}>
      <Grid item xs={12} mb={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>{title}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ImageGalleryList>
          {pictures
            .filter((item) => item.pics && Array.isArray(item.pics) && item.pics.length > 0)
            .map((item) => (
              <ImageListItem key={item.pics[0]} onClick={() => onSelect(item)} sx={{ cursor: 'pointer', maxHeight: 300, overflow: 'hidden' }}>
                <img
                  src={`${item.show || item.pics[0]}`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                />
              </ImageListItem>
            ))}
        </ImageGalleryList>
      </Grid>

      <PicModal
        open={picModalOpen}
        close={onCloseModal}
        item={selectedItem}
      />
    </Grid>
  );
}
