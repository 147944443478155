import React from 'react';
import Box from '@mui/material/Box';
import Topbar from 'components/Topbar';
import Me from 'components/Me';
import Portfolio from 'components/Portfolio';
import VideoPortfolio from 'components/VideoPortfolio';
import Copyright from 'components/Copyright';
import Divider from 'components/Divider';
import Jobs from 'components/Jobs';
import Studies from 'components/Studies';

import { picturesRea, videoRea, picturesApprentissage } from '../../assets/pictures';

function Home() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Topbar />

      <Me />

      <Divider />

      <Portfolio title="Redressage - Photos" pictures={picturesRea} />

      <Divider />

      <VideoPortfolio title="Redressage - Vidéos" videos={videoRea} />

      <Divider />

      <Portfolio title="Apprentissage" pictures={picturesApprentissage} />

      <Divider />

      <Jobs />

      <Divider />

      <Studies />

      <Divider />

      <Copyright />
    </Box>
  );
}

export default Home;
