import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

export default function Copyright() {
  return (
    <Grid container spacing={2} mt={10} mb={10}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Typography sx={{ fontSize: '15px', textAlign: 'center' }}>
          {`© Copyright site, texte, photos et vidéos: ${new Date().getFullYear()} - Aubry ANDRÉ`}
        </Typography>
        <Typography sx={{ fontSize: '13px', textAlign: 'center', paddingTop: '1em' }}>
          © Copyright logos: auteurs et propriétaires respectifs
        </Typography>
      </Grid>
    </Grid>
  );
}
