import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';

import store from 'store';

import Home from 'containers/Home';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        {/* Routes definition */}
        <Routes>
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
