import { createAction } from '@reduxjs/toolkit';

export const ADD_LOADER = 'ADD_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';

export const addLoader = createAction(ADD_LOADER, (actionType: string) => ({
  payload: { actionType },
}));

export const removeLoader = createAction(REMOVE_LOADER, (actionType: string) => ({
  payload: { actionType },
}));
