import React from 'react';
import ReactDOM from 'react-dom';

import App from 'containers/App';

import 'react-image-lightbox/style.css';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
