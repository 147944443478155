import { createReducer } from '@reduxjs/toolkit';

import { ADD_LOADER, REMOVE_LOADER } from 'actions/loaders';
import { Loader } from 'types/loaders';

const initialState: any = [];

const loadersReducer = createReducer(initialState, {

  [ADD_LOADER]: (state, { payload: { actionType } }) => {
    const newLoader: Loader = {
      actionType,
    };

    return [...state, newLoader];
  },

  [REMOVE_LOADER]: (state, { payload: { actionType } }) => {
    const filteredState = state.filter((loader: Loader) => loader.actionType !== actionType);

    return filteredState;
  },

});

export default loadersReducer;
